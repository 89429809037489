import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import {
  middlewares as queryMiddlewares,
  reducers as queryReducers,
} from './query';
import { CoreStore } from 'store';

export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: getReducers(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([sagaMiddleware, ...queryMiddlewares]),
  enhancers: [
    createInjectorsEnhancer({
      createReducer: injectedReducers => getReducers(injectedReducers),
      runSaga: sagaMiddleware.run,
    }),
  ],
  devTools: {
    shouldHotReload: false,
  },
});

export type AppDispatch = typeof store.dispatch;

// Root reducers
function getReducers(injectedReducers = {}) {
  const rootReducer = combineReducers({
    [CoreStore.name]: CoreStore.reducer,
    ...queryReducers,
    ...injectedReducers,
  });
  return rootReducer;
}
// Root saga
sagaMiddleware.run(CoreStore.saga);
