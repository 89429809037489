import Layout from 'modules/@core/components/Layout';
import NoPermission from 'modules/@core/components/NoPermission';
// import NotFound from 'modules/@core/components/NotFound';

const rootRoute: Required<PageRouteConfigProps> = {
  path: '/',
  key: 'root',
  title: 'root',
  icon: null,
  hideNavMenu: false,
  Component: Layout,
  disableLayout: false,
  permission: {
    onlyGuest: false,
    permissions: [],
    permissionsCondition: 'ALL',
    handleUnGuest: '/',
    handleUnauthenticated: '/login',
    handleNoPermission: <NoPermission />,
  },
  children: [],
  // childrenHandleNotFound: <NotFound />,
  childrenHandleNotFound: '/login',
};

export default rootRoute;
