import { PayloadAction } from '@reduxjs/toolkit';
import { SidebarMode, initialState } from './initial-state';
import appHttpRequest from 'app/network/app-http-request';
import { appCreateSlice } from 'app/redux/tools';
import { storage } from 'common/utils';
import authApi from 'services/auth';

export default appCreateSlice({
  name: '@core',
  initialState: initialState,
  reducers: {
    setGrantedPageRouteConfigs(
      state,
      action: PayloadAction<GrantedPageRouteConfigProps[]>,
    ) {
      state.grantedPageRouteConfigs = action.payload;
    },
    setCurrentGrantedPageRouteConfigs(
      state,
      action: PayloadAction<GrantedPageRouteConfigProps>,
    ) {
      state.currentGrantedPageRouteConfigs = action.payload;
    },

    openSidebar: state => {
      state.sidebarOpen = true;
    },
    closeSidebar: state => {
      state.sidebarOpen = false;
    },
    setSidebarMode: (state, action: PayloadAction<SidebarMode | undefined>) => {
      state.sidebarMode = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, action: PayloadAction<any>) => {
          state.userInfo = action.payload;
          state.authToken = action.payload.token;
          state.userPermissions = action.payload.permissions;
          state.isAuthenticated = !!action.payload.token;

          // Side effects
          storage.set('__USER_INFO', action.payload);
          storage.set('__AUTH_TOKEN', action.payload.token);
          storage.set('__AUTH_PERMISSIONS', action.payload.permissions);
          appHttpRequest.setAuthorizationToken(action.payload.token);
        },
      )
      .addMatcher(authApi.endpoints.logout.matchFulfilled, state => {
        state.authToken = undefined;
        state.userPermissions = [];
        state.isAuthenticated = false;

        // Side effects
        storage.remove('__USER_INFO');
        storage.remove('__AUTH_TOKEN');
        storage.remove('__AUTH_PERMISSIONS');
        appHttpRequest.setAuthorizationToken('');
      });
  },
});
