import { useEffect, useState } from 'react';
import {
  ModalBaseProps,
  ModalController,
  ModalStoreEntity,
} from './ModalController';

export function useModalComponentControl<P extends ModalBaseProps, W>(
  control: ModalController<W>,
  Component: ModalStoreEntity<P, W>['Component'],
  initialProps?: ModalStoreEntity<P, W>['props'],
  wrapperProps?: W,
) {
  const [modalControl] = useState(() =>
    control.createModalComponentControl(Component, initialProps, wrapperProps),
  );

  useEffect(() => {
    return () => {
      modalControl.close();
    };
  }, [modalControl]);

  return modalControl;
}
