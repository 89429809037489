import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { weekdays, weekdaysShort } from 'moment';

class CustomString extends String {
  charAt(_: number): string {
    return weekdaysShort()[weekdays().indexOf(this.valueOf())];
  }
}

class DateAdapter extends AdapterMoment {
  getWeekdays = (): string[] => {
    return weekdays(true).map(v => new CustomString(v) as string);
  };
}

export default DateAdapter;
