import {
  Theme,
  ThemeOptions,
  createTheme,
  useTheme,
} from '@mui/material/styles';
import _ from 'lodash';
import base from 'styles/themes/@base';

interface CustomOptions {
  name: string;
  customAbc?: string;
}

export type AppTheme = Theme & CustomOptions;

export type AppThemeOptions = ThemeOptions & CustomOptions;

export function createAppTheme(options: AppThemeOptions) {
  return createTheme(_.merge(base, options)) as AppTheme;
}

export const useAppTheme: <T = AppTheme>() => T = useTheme;
