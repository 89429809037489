import { WithOptionsQueryArg, appApi } from 'app/redux/query';

const authApi = appApi.injectEndpoints({
  endpoints: build => ({
    login: build.mutation<any, WithOptionsQueryArg<any, any>>({
      query: arg => ({
        url: '/auth/login',
        method: 'POST',
        data: arg.data,
      }),
    }),

    logout: build.mutation<any, WithOptionsQueryArg<any, any>>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
    }),
  }),
});

export default authApi;
