import _ from 'lodash';
import { HttpResponse } from './http-response';

/**
 * Error
 * @SS Server Success Response Data Props
 * @SE Server Error Response Props
 */
export class HttpRequestError<SE> extends Error {
  isHttpRequestError = true;
  response: HttpResponse<unknown, SE>;
  constructor(response: HttpResponse<unknown, SE>, message?: string) {
    super(
      message ||
        (_.get(response?.error, 'message') as string) ||
        response.httpStatusMessage,
    );
    this.name = this.constructor.name;
    this.response = response;
  }
}
