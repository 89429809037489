import appApi from './app';
import optionsMiddleware from './optionsMiddleware';

export { appApi };

export const reducers = {
  [appApi.reducerPath]: appApi.reducer,
};

export const middlewares = [
  appApi.middleware,

  // Always put the options middleware in last
  optionsMiddleware,
];

interface OptionsQueryArg<DataType, ResultType> {
  showLoading?: boolean;
  notifySuccess?:
    | boolean
    | string
    | ((dataQueryArg: DataType, result: ResultType) => string);
  notifyError?:
    | boolean
    | string
    | ((dataQueryArg: DataType, result: ResultType) => string);
}
export type WithOptionsQueryArg<DataType, ResultType> =
  DataType extends Exclude<DataType, void>
    ? {
        data?: DataType;
        options?: OptionsQueryArg<DataType, ResultType>;
      }
    : DataType extends void
    ? void | {
        data?: DataType;
        options?: OptionsQueryArg<DataType, ResultType>;
      }
    : {
        data?: DataType;
        options?: OptionsQueryArg<DataType, ResultType>;
      };
