import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingContentProps } from '@libs/dynamic-loading';

export default function CircularProgressLoadingContent({
  visible,
}: LoadingContentProps) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={visible}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
