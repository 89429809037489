import Modal from '@mui/material/Modal';
import { ModalWrapperProps } from '..';

interface MyModalWrapperProps {
  a: string;
}
export default function MyModalWrapper({
  Component,
  props,
  wrapperProps,
}: ModalWrapperProps<MyModalWrapperProps>) {
  return (
    <Modal open={true} onClose={props?.modalThis?.close}>
      <div>
        <button
          onClick={() => {
            props?.modalThis?.close?.();
          }}
        >
          wrapper close
        </button>
        {/* asdadadadsdsa<button onClick={() => modalThis?.close?.()}>CLOSE</button> */}
        {Component && <Component {...props} />}
      </div>
    </Modal>
  );
}
