/* eslint-disable no-restricted-imports */
import {
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  CreateSliceOptions,
  SliceCaseReducers,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../store/RootState';
import { AppDispatch } from '.';

/**
 * Wrap createSlice for required BaseReduxStateProps
 * Add general action/reducer like clear,..
 * Typed state
 */
export function appCreateSlice<
  State extends BaseReduxStateProps,
  CaseReducers extends SliceCaseReducers<State>,
>(options: CreateSliceOptions<State, CaseReducers, keyof RootState>) {
  return createSlice({
    name: options.name,
    initialState: options.initialState,
    reducers: {
      clear() {
        return options.initialState;
      },
      ...options.reducers,
    },
    extraReducers: options.extraReducers,
  });
}

/**
 * Typed state
 */
type AppAsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};
export function appCreateAsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig extends AppAsyncThunkConfig,
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
) {
  return createAsyncThunk(typePrefix, payloadCreator, options);
}
