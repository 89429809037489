import lazyload from 'app/router/lazyload';

const ReceptionCallPage = lazyload(
  () => import('./pages/reception-call-page'),
  module => module.default,
);

const ReceptionPatientsPage = lazyload(
  () => import('./pages/reception-patients-page'),
  module => module.default,
);

const ReceptionPatientsDetailsPage = lazyload(
  () => import('./pages/reception-patients-details-page'),
  module => module.default,
);

export default {
  route: {
    key: 'reception',
    path: '/reception',
    title: 'Quản lý tiếp nhận',
    permission: {
      permissions: ['RECEPTION'],
    },
    children: [
      {
        key: 'call',
        path: '/call',
        title: 'Quản lý điều phối STT tiếp nhận',
        Component: ReceptionCallPage,
      },
      {
        key: 'patients',
        path: '/patients',
        title: 'Quản lý tiếp nhận, ĐK khám chữa bệnh',
        Component: ReceptionPatientsPage,
        // children: [ // TODO
        //   {
        //     key: 'patients-details',
        //     path: '/patients/details',
        //     title: 'Quản lý tiếp nhận, ĐK khám chữa bệnh',
        //     Component: ReceptionPatientsDetailsPage,
        //   },
        // ],
      },
      {
        key: 'patients-details',
        path: '/patients/details',
        title: 'Quản lý tiếp nhận, ĐK khám chữa bệnh', // TODO: fix not show title if hideNavMenu = true
        Component: ReceptionPatientsDetailsPage,
        hideNavMenu: true,
      },
    ],
  },
} as AppModuleProps;
