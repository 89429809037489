import { storage } from 'common/utils';

export type SidebarMode = 'persistent' | 'temporary';

export interface CoreStateProps extends BaseReduxStateProps {
  userInfo: any;
  userPermissions: PermissionType[];
  authToken?: string;
  isAuthenticated: boolean;
  grantedPageRouteConfigs: GrantedPageRouteConfigProps[];
  currentGrantedPageRouteConfigs?: GrantedPageRouteConfigProps;

  sidebarOpen?: boolean;
  sidebarMode?: SidebarMode;
}

export const initialState: CoreStateProps = {
  userInfo: storage.get('__USER_INFO'),
  userPermissions: storage.get<PermissionType[]>('__AUTH_PERMISSIONS') || [],
  authToken: storage.get('__AUTH_TOKEN'),
  get isAuthenticated() {
    return !!this.authToken;
  },
  grantedPageRouteConfigs: [],

  sidebarOpen: true,
};
