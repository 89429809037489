import lazyload from 'app/router/lazyload';

const ReportTreatmentPage = lazyload(
  () => import('./pages/report-treatment-page'),
  module => module.default,
);

export default {
  route: {
    key: 'report',
    path: '/report',
    title: 'Báo cáo',
    permission: {
      permissions: ['RECEPTION'],
    },
    Component: ReportTreatmentPage,
  },
} as AppModuleProps;
