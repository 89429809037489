import {
  Middleware,
  PayloadAction,
  isFulfilled,
  isRejected,
} from '@reduxjs/toolkit';
import { WithOptionsQueryArg } from '.';
import loadingControl from 'app/loading';
import { notify } from 'app/notification';
import { getMessage } from 'common/utils';

const optionsMiddleware: Middleware =
  store =>
  next =>
  (
    action: PayloadAction<
      unknown,
      never,
      {
        arg: { originalArgs: WithOptionsQueryArg<unknown, unknown> };
        requestId: string;
      }
    >,
  ) => {
    const options = action.meta?.arg?.originalArgs?.options;
    if (options?.showLoading) {
      loadingControl.show(action.meta.requestId);
    }
    if (isFulfilled(action)) {
      if (options?.showLoading) {
        loadingControl.hide(action.meta.requestId);
      }
      if (typeof options?.notifySuccess === 'string') {
        notify('SUCCESS', options.notifySuccess);
      } else if (typeof options?.notifySuccess === 'function') {
        notify(
          'SUCCESS',
          options.notifySuccess(
            action.meta.arg.originalArgs.data,
            action.payload,
          ),
        );
      } else if (options?.notifySuccess) {
        notify('SUCCESS', getMessage(action.payload, 'Successfully'));
      }
      return next(action);
    } else if (isRejected(action)) {
      if (options?.showLoading) {
        loadingControl.hide(action.meta.requestId);
      }
      if (typeof options?.notifyError === 'string') {
        notify('ERROR', options.notifyError);
      } else if (typeof options?.notifyError === 'function') {
        notify(
          'ERROR',
          options.notifyError(
            action.meta.arg.originalArgs.data,
            action.payload,
          ),
        );
      } else if (options?.notifyError || options?.notifyError === undefined) {
        notify('ERROR', getMessage(action.payload, 'Failure'));
      }
    }
    return next(action);
  };

export default optionsMiddleware;
