import { useEffect, useState } from 'react';
import { LoadingController } from './LoadingController';
import './styles.css';

interface LoadingContainerProps {
  control: LoadingController;
}

export default function LoadingContainer({ control }: LoadingContainerProps) {
  const [list, setList] = useState(control.getStore().entries);

  useEffect(() => {
    const unsub = control.getStore().subscribe(() => {
      setList([...control.getStore().entries]);
    });
    return () => unsub();
  }, [control]);

  const Content = control.getOptions().Content;

  return (
    <div className="overlay-loading-backdrop">
      {<Content visible={list.length > 0} />}
    </div>
  );
}
