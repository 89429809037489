/* eslint-disable no-restricted-imports */
import { ToastOptions, toast } from 'react-toastify';
import { appContextValueRef } from 'app/context';

export function notify(
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO',
  content: string,
) {
  const otp: ToastOptions = {};
  switch (type) {
    case 'SUCCESS':
      otp.type = 'success';
      otp.autoClose = 2000;
      otp.style = {
        backgroundColor:
          appContextValueRef.current.selectedTheme.palette.success.main,
      };
      break;
    case 'ERROR':
      otp.type = 'error';
      otp.autoClose = 3000;
      otp.style = {
        backgroundColor:
          appContextValueRef.current.selectedTheme.palette.error.main,
      };
      break;
    case 'WARNING':
      otp.type = 'warning';
      otp.autoClose = 3000;
      otp.style = {
        backgroundColor:
          appContextValueRef.current.selectedTheme.palette.warning.main,
      };
      break;
    case 'INFO':
      otp.type = 'info';
      otp.autoClose = 3000;
      otp.style = {
        backgroundColor:
          appContextValueRef.current.selectedTheme.palette.info.main,
      };
      break;
    default:
      otp.type = 'default';
      otp.autoClose = 3000;
      otp.style = {
        backgroundColor:
          appContextValueRef.current.selectedTheme.palette.secondary.main,
      };
      break;
  }
  toast(content, otp);
}
