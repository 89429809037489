import CircularProgressLoadingContent from './CircularProgressLoadingContent';
import { LoadingController } from '@libs/dynamic-loading';

const loadingControl = new LoadingController({
  Content: CircularProgressLoadingContent,
}).mount();

export default loadingControl;

export { useLoading, useLoadingRedux } from './hooks';
