import Box from '@mui/material/Box';
import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

export default function Layout() {
  return (
    <Box display="flex" flexDirection="row">
      <Sidebar />
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        flex={1}
        width={0}
      >
        <Header />
        <Content flex={1} />
        <Footer />
      </Box>
    </Box>
  );
}
