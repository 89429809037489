import React, { forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

export default forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box ref={ref} position="relative" {...props}>
      <Outlet />
    </Box>
  );
});
