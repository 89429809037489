import { PropsWithChildren, useEffect, useState } from 'react';
import {
  AppContext,
  AppContextProps,
  appContextValueRef,
  defaultValue,
} from '.';
import { AppTheme } from 'app/theme';

export default function AppContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [value, setValue] = useState<AppContextProps>(defaultValue);
  useEffect(() => {
    value.selectTheme = (theme: AppTheme) => {
      setValue({ ...value, selectedTheme: theme });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  appContextValueRef.current = value;
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
