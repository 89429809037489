import { createAppTheme } from 'app/theme';

export default createAppTheme({
  name: 'Light',
  palette: {
    mode: 'light',
  },
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          minHeight: '40px',
        },
      },
    },
  },
});
