import { Fragment, useEffect, useState } from 'react';
import { ModalController } from './ModalController';
import './styles.css';

interface ModalContainerProps<W> {
  control: ModalController<W>;
}
export default function ModalContainer<W>({ control }: ModalContainerProps<W>) {
  const [list, setList] = useState(control.getStore().entries);

  useEffect(() => {
    const unsub = control.getStore().subscribe(() => {
      setList([...control.getStore().entries]);
    });
    return () => unsub();
  }, [control]);

  const { containerStyle, containerClassname, WrapperComponent } =
    control.getOptions();

  return (
    <div
      style={containerStyle}
      className={`overlay-modal-backdrop${
        containerClassname ? ' ' + containerClassname : ''
      }`}
    >
      {list.map(({ key, value }) => {
        if (!value) return null;
        const { Component, props } = value;
        return (
          <Fragment key={key}>
            {WrapperComponent ? (
              <WrapperComponent {...value} />
            ) : Component ? (
              <Component {...props} />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}
