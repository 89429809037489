import lazyload from 'app/router/lazyload';

const LoginPage = lazyload(
  () => import('./pages/login-page'),
  module => module.default,
);

export default {
  route: {
    key: 'login',
    path: '/login',
    title: 'Login',
    Component: LoginPage,
    disableLayout: true,
    permission: {
      allowAnyone: false,
      onlyGuest: true,
      // handleUnGuest: '/',
    },
  },
} as AppModuleProps;
