/* eslint-disable @typescript-eslint/ban-types */
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import appHttpRequest from 'app/network/app-http-request';
import {
  HttpRequestError,
  HttpRequestOptionProps,
} from 'app/network/http-request';

type AppBaseQuery = BaseQueryFn<
  HttpRequestOptionProps<unknown>,
  unknown,
  HttpRequestError<unknown>,
  {},
  {}
>;

const appBaseQuery: AppBaseQuery = async function (args) {
  try {
    const response = await appHttpRequest.request(args);
    return { data: response };
  } catch (error) {
    return { error: error as HttpRequestError<unknown> };
  }
};

const appApi = createApi({
  reducerPath: '$app',
  baseQuery: appBaseQuery,
  endpoints: () => ({}),
});

export default appApi;
