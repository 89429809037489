import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ModalContainer } from '@libs/dynamic-modal';
import App from 'app';
import AppContextProvider from 'app/context/AppContextProvider';
import modalControl from 'app/modal';
import NotificationContainer from 'app/notification/NotificationContainer';
import AppReduxProvider from 'app/redux/AppReduxProvider';
import AppThemeProvider from 'app/theme/AppThemeProvider';
import DateAdapter from 'common/components/DateAdapter';
import 'moment/locale/vi';
import 'styles/global.styles.css';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppThemeProvider>
        <AppReduxProvider>
          <LocalizationProvider dateAdapter={DateAdapter} adapterLocale="vi">
            <CssVarsProvider />
            <CssBaseline />
            <App />
            <ModalContainer control={modalControl} />
            <NotificationContainer />
          </LocalizationProvider>
        </AppReduxProvider>
      </AppThemeProvider>
    </AppContextProvider>
  </BrowserRouter>,
);
