import { DependencyList, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { TypedUseSelectorHook } from 'react-redux/es/types';
import { RootState } from '../../store/RootState';
import { AppDispatch } from '.';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function useStateEvent<T>(
  currentState: T,
  triggerState: T,
  callback: () => void,
  callbackDepts: DependencyList = [],
) {
  const previousRef = useRef<T>(currentState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(callback, callbackDepts);
  useEffect(() => {
    if (currentState !== previousRef.current && currentState === triggerState) {
      handler();
    }
    previousRef.current = currentState;
  }, [currentState, triggerState, handler]);
}

export function useSelectorStateEvent(
  stateSelector: (state: RootState) => AsyncState | undefined,
  triggerState: AsyncState,
  callback: () => void,
  callbackDepts: DependencyList = [],
) {
  const currentState = useSelector(stateSelector);
  useStateEvent(currentState, triggerState, callback, callbackDepts);
}
