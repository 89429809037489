import KioskLayout from './components/KioskLayout';
import lazyload from 'app/router/lazyload';

const KioskRoomSelectorPage = lazyload(
  () => import('./pages/kiosk-room-selector-page'),
  module => module.default,
);
const KioskQueueNumberPage = lazyload(
  () => import('./pages/kiosk-queue-number-page'),
  module => module.default,
);

const LCDCallPage = lazyload(
  () => import('./pages/lcd-call-page'),
  module => module.default,
);
const LCDInjectionsPatients = lazyload(
  () => import('./pages/lcd-injections-patients'),
  module => module.default,
);

export default {
  route: {
    key: 'devices',
    path: '/devices',
    title: 'Thiết bị',
    permission: {
      //   permissions: ['RADIATION_THERAPY'],
      permissions: ['MEDICAL_RECORD'],
    },
    // hideNavMenu: true,
    children: [
      {
        key: 'kiosk',
        path: '/kiosk',
        title: 'Kiosk',
        Component: KioskLayout,
        children: [
          {
            key: 'room-selector',
            path: '/room-selector',
            title: 'Chọn phòng khám',
            Component: KioskRoomSelectorPage,
          },
          {
            key: 'queue-number',
            path: '/queue-number',
            title: 'Lấy số thứ tự',
            Component: KioskQueueNumberPage,
          },
        ],
      },
      {
        key: 'lcd',
        path: '/lcd',
        title: 'LCD',
        Component: KioskLayout,
        children: [
          {
            key: 'call',
            path: '/call',
            title: 'Call',
            Component: LCDCallPage,
          },
          {
            key: 'injections-patients',
            path: '/injections-patients',
            title: 'Danh sách người bệnh nhờ truyền thuốc',
            Component: LCDInjectionsPatients,
          },
        ],
      },
    ],
  },
} as AppModuleProps;
