import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from 'assets/images/logo_bvcr.svg';
import PageContent from 'modules/@core/components/Layout/PageContent';

export default function KioskLayout() {
  const navigation = useNavigate();

  return (
    <PageContent
      fullScreen
      p={0}
      bgcolor="white"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        bgcolor="primary.main"
        p={2}
      >
        <Box>
          <img height="100%" src={logo} alt="" onClick={() => navigation(-1)} />
        </Box>
        <Box textAlign="end" fontWeight="bold" color="white">
          <Typography fontSize="1.5em">BỆNH VIỆN CHỢ RẪY</Typography>
          <Typography>CHẤT LƯỢNG - VĂN MINH - HIỆN ĐẠI - NGHĨA TÌNH</Typography>
        </Box>
      </Box>
      <Outlet />
    </PageContent>
  );
}
