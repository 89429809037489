import lazyload from 'app/router/lazyload';

const CTManagementPage = lazyload(
  () => import('./pages/ct-management-page'),
  module => module.default,
);

export default {
  route: {
    key: 'ct-management',
    path: '/ct-management',
    title: 'Quản lý CT',
    Component: CTManagementPage,
    permission: {
      // permissions: ['CT_MANAGEMENT'],
      permissions: ['MEDICAL_RECORD'],
    },
  },
} as AppModuleProps;
