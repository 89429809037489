import core from './@core';
import chemotherapy from './chemotherapy';
import ctManagement from './ct-management';
import devices from './devices';
import injections from './injections';
import medicalRecord from './medical-record';
import radiationTherapy from './radiotherapy';
import reception from './reception';
import report from './report';

export const modules: AppModuleProps[] = [
  core,
  reception,
  medicalRecord,
  ctManagement,
  radiationTherapy,
  chemotherapy,
  injections,
  report,
  devices,
];
