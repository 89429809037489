import Wrapper, { WrapperProps } from './Wrapper';
import { ModalBaseProps, ModalController } from '@libs/dynamic-modal';

const modalControl = new ModalController({
  WrapperComponent: Wrapper,
});

export default modalControl;

export type { ModalBaseProps, WrapperProps };
