import { HttpRequest, MockResponseConfig } from './http-request';
import env from 'app/env';

const appHttpRequest = new HttpRequest({
  baseURL: env.BASE_URL.APP,
  successResponseFormatter(response) {
    return response;
  },
});
export default appHttpRequest;

/**
 * Mock requests for testing
 */
export class AppMockResponseConfig<S, H, E> extends MockResponseConfig<
  S,
  H,
  E
> {}
if (process.env.REACT_APP_MOCK) {
  import('test/app-http-request');
}
