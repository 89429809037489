import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingContentProps } from '../..';

const fadeIn = keyframes`
  from {
    pointer-events: none;
    opacity: 0;
  }

  to {
    pointer-events: all;
    opacity: 0.5;
  }
`;

const fadeOut = keyframes`
  from {
    pointer-events: all;
    opacity: 0.5;
  }

  to {
    pointer-events: none;
    opacity: 0;
  }
`;

const Overlay = styled.div<{ display: string }>`
  width: 100%;
  height: 100%;
  background: #000000;
  pointer-events: none;
  opacity: 0;
  animation: ${props => (props.display === 'true' ? fadeIn : fadeOut)} 0.3s
    forwards ease-in-out;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function CircularProgressLoadingContent({
  visible,
}: LoadingContentProps) {
  return (
    <Overlay display={String(visible)}>
      <Content>
        <CircularProgress />
      </Content>
    </Overlay>
  );
}
