export * as storage from './persistent-storage';
export * as json from './json';
export * as dataGrid from './data-grid';
export * as pathTools from './path-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMessage(data: any, def = 'Unknown message') {
  if (data instanceof Error && process.env.NODE_ENV === 'development') {
    console.error(data);
  }
  switch (typeof data) {
    case 'string':
    case 'number':
    case 'boolean':
      return data.toString();

    case 'object': {
      if (!data) return def; // Null
      if (data?.message) return getMessage(data.message);
      const values = Object.values(data);
      if (values.length > 0) return def;
      return values
        .filter(d => typeof d === 'string' || typeof d === 'number')
        .join(', ');
    }

    case 'symbol':
      return data.description;

    default:
      return def;
  }
}
