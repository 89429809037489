/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Head from './Head';
import { getGrantedPageRoutesConfigs } from '.';

function renderRoute(
  {
    key,
    path,
    title,
    Component,
    permission,
    missingRequiredAccess,
    children,
  }: GrantedPageRouteConfigProps,
  childrenRoutes?: JSX.Element | JSX.Element[],
) {
  switch (missingRequiredAccess) {
    case 'GUEST':
      return (
        <Route
          key={key}
          path={path}
          element={
            typeof permission?.handleUnGuest === 'string' ? (
              <Navigate to={permission.handleUnGuest} />
            ) : (
              permission?.handleUnGuest
            )
          }
        >
          {childrenRoutes}
        </Route>
      );
    case 'AUTHENTICATED_USER':
      return (
        <Route
          key={key}
          path={path}
          element={
            typeof permission?.handleUnauthenticated === 'string' ? (
              <Navigate to={permission.handleUnauthenticated} />
            ) : (
              permission?.handleUnauthenticated
            )
          }
        >
          {childrenRoutes}
        </Route>
      );
    case 'AUTHORIZED_USER':
      return (
        <Route
          key={key}
          path={path}
          element={
            typeof permission?.handleNoPermission === 'string' ? (
              <Navigate to={permission.handleNoPermission} />
            ) : (
              permission?.handleNoPermission
            )
          }
        >
          {childrenRoutes}
        </Route>
      );
    default:
      return (
        <Route
          key={key}
          path={path}
          element={
            (children && children.length > 0) || !Component ? undefined : (
              <Head title={title}>
                <Component />
              </Head>
            )
          }
        >
          {childrenRoutes}
        </Route>
      );
  }
}

function renderDefaultPageRoute(
  grantedPageRouteConfig: GrantedPageRouteConfigProps,
) {
  const defs = (grantedPageRouteConfig.children || []).filter(
    c => c.missingRequiredAccess === 'NONE',
  );
  if (defs.length === 0) return null;
  return <Route index element={<Navigate to={defs[0].path} />} />;
}

function renderPageRoutes(
  grantedPageRouteConfigs: GrantedPageRouteConfigProps[],
) {
  const list: JSX.Element[] = [];
  for (const config of grantedPageRouteConfigs) {
    const withLayout: GrantedPageRouteConfigProps[] = [];
    const withoutLayout: GrantedPageRouteConfigProps[] = [];
    for (const c of config.children || []) {
      if (c.disableLayout) withoutLayout.push(c);
      else withLayout.push(c);
    }

    list.push(
      renderRoute(
        config,
        (config.children || []).length > 0 ? (
          <>
            {renderDefaultPageRoute(config)}
            {withLayout.length > 0 && (
              <Route element={config.Component && <config.Component />}>
                {renderPageRoutes(withLayout)}
              </Route>
            )}
            {renderPageRoutes(withoutLayout)}
            <Route
              path="*"
              element={
                typeof config.childrenHandleNotFound === 'string' ? (
                  <Navigate to={config.childrenHandleNotFound} />
                ) : (
                  config.childrenHandleNotFound
                )
              }
            />
          </>
        ) : undefined,
      ),
    );
  }
  return list;
}

interface AppRoutesProps {
  isAuthenticated: boolean;
  userPermissions: PermissionType[];
  onGranted?: (grantedPageRouteConfigs: GrantedPageRouteConfigProps[]) => void;
}
export default function AppRoutes({
  isAuthenticated,
  userPermissions,
  onGranted,
}: AppRoutesProps) {
  const grantedPageRouteConfigs = useMemo(() => {
    const granted = getGrantedPageRoutesConfigs(
      isAuthenticated,
      userPermissions,
    );
    return granted;
  }, [isAuthenticated, userPermissions]);

  useEffect(() => {
    onGranted?.(grantedPageRouteConfigs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantedPageRouteConfigs]);

  return <Routes>{renderPageRoutes(grantedPageRouteConfigs)}</Routes>;
}
