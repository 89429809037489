/* eslint-disable import/order */
/* eslint-disable import/first */
import { LoadingController } from './LoadingController';
export { default as LoadingContainer } from './LoadingContainer';
export type { LoadingContentProps } from './LoadingController';
export { LoadingController };

// Default loading controller
import { CircularProgressLoadingContent } from './templates';
export const loadingControl = new LoadingController({
  Content: CircularProgressLoadingContent,
}).mount();
export default loadingControl;
