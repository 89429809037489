import Dialog, { DialogProps } from '@mui/material/Dialog';
import ConfirmationLayout from './ConfirmationLayout';
import DefaultLayout from './DefaultLayout';
import { ModalWrapperProps } from '@libs/dynamic-modal';

export type WrapperProps = Partial<DialogProps> & {
  layout?: 'none' | 'default' | 'confirmation';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layoutProps?: any; // TODO: type
};
export default function Wrapper(p: ModalWrapperProps<WrapperProps>) {
  const { Component, props, wrapperProps } = p;
  const renderContent = () => {
    if (wrapperProps?.layout === 'none')
      return typeof Component === 'function' && <Component {...props} />;
    if (wrapperProps?.layout === 'confirmation')
      return <ConfirmationLayout {...p} />;
    return <DefaultLayout {...p} />;
  };

  return (
    <Dialog
      onClose={props?.modalThis?.close}
      fullWidth
      {...wrapperProps}
      open={true}
    >
      {renderContent()}
    </Dialog>
  );
}
