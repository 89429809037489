import { forwardRef, useCallback, useEffect, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box, { BoxProps } from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useMatch, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import logoBVCR from 'assets/images/logo_bvcr.svg';
import { CoreStore } from 'store';

const sidebarWidth = 300;

function NavItem(grantedPageRouteConfig: GrantedPageRouteConfigProps) {
  const { path, title: name, icon, children, level } = grantedPageRouteConfig;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const match = useMatch({
    path,
    end: false,
  });
  const matchExact = useMatch(path);

  // TODO: use matchExact outside or useMatches
  useEffect(() => {
    if (!matchExact) return;
    dispatch(
      CoreStore.actions.setCurrentGrantedPageRouteConfigs(
        grantedPageRouteConfig,
      ),
    );
  }, [dispatch, grantedPageRouteConfig, matchExact]);

  const sidebarMode = useAppSelector(CoreStore.select('sidebarMode'));

  const [open, setOpen] = useState(!!match);

  const closeSidebar = useCallback(() => {
    dispatch(CoreStore.actions.closeSidebar());
  }, [dispatch]);

  function handleClick() {
    if (children) {
      setOpen(!open);
      return;
    }
    navigate(path);
    if (sidebarMode === 'temporary') closeSidebar();
  }

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          borderRadius: 1,
          background: matchExact
            ? '#e0e0e0'
            : level === 1
            ? '#1976d289'
            : undefined, // TODO level style
          marginY: 0.5,
        }}
      >
        {icon && <Box pr={1}>{icon}</Box>}
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            fontWeight: level === 1 ? 'bold' : undefined,
            fontSize: level > 1 ? '0.9em' : undefined,
          }}
        />
        {children && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 1 }}>
            {renderNav(children)}
          </List>
        </Collapse>
      )}
    </>
  );
}

function renderNav(grantedPageRouteConfigs: GrantedPageRouteConfigProps[]) {
  const list =
    grantedPageRouteConfigs?.[0]?.key === 'root'
      ? grantedPageRouteConfigs[0].children || []
      : grantedPageRouteConfigs;

  return list
    .filter(r => r.missingRequiredAccess === 'NONE' && !r.hideNavMenu)
    .map(r => <NavItem {...r} />);
}

export default forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const dispatch = useAppDispatch();

  const sidebarOpen = useAppSelector(CoreStore.select('sidebarOpen'));
  const sidebarMode = useAppSelector(CoreStore.select('sidebarMode'));
  const grantedPageRouteConfigs = useAppSelector(
    CoreStore.select('grantedPageRouteConfigs'),
  );

  const closeSidebar = useCallback(() => {
    dispatch(CoreStore.actions.closeSidebar());
  }, [dispatch]);
  const setSidebarMode = useCallback(
    (mode: typeof sidebarMode) => {
      dispatch(CoreStore.actions.setSidebarMode(mode));
    },
    [dispatch],
  );

  useEffect(() => {
    function callback() {
      if (window.innerWidth < 800) setSidebarMode('temporary');
      else setSidebarMode('persistent');
    }
    window.addEventListener('resize', callback);
    callback();
    return function () {
      window.removeEventListener('resize', callback);
    };
  }, [setSidebarMode]);

  const renderDrawer = (
    <Drawer
      anchor="left"
      variant={sidebarMode}
      open={sidebarOpen}
      onClose={closeSidebar}
    >
      <Box
        ref={ref}
        sx={{ width: sidebarWidth }}
        role="presentation"
        // onClick={closeSidebar}
        // onKeyDown={closeSidebar}
      >
        <Box display="flex" justifyContent="center" padding={4}>
          <Box component="img" src={logoBVCR} width={120} />
        </Box>

        <RoomSelect />

        <List sx={{ width: '100%', pt: 0.5 }} component="nav">
          {renderNav(grantedPageRouteConfigs)}
        </List>
      </Box>
    </Drawer>
  );

  if (sidebarMode === 'persistent')
    return (
      <Collapse in={sidebarOpen} orientation="horizontal">
        <Box sx={{ width: sidebarWidth }} {...props}>
          {renderDrawer}
        </Box>
      </Collapse>
    );

  return renderDrawer;
});

function RoomSelect() {
  return (
    <Select
      fullWidth
      defaultValue={0}
      size="small"
      sx={{
        backgroundColor: 'primary.main',
        color: 'white',
        pt: 1,
        pb: 0.8,
        fontWeight: 'bold',
      }}
    >
      <MenuItem value={0}>PHÒNG KHÁM HÓA XẠ TRỊ</MenuItem>
      <MenuItem value={1}>PHÒNG KHÁM HÓA TRUYỀN</MenuItem>
      <MenuItem value={2}>PHÒNG KHÁM 1</MenuItem>
      <MenuItem value={3}>PHÒNG KHÁM 2</MenuItem>
      <MenuItem value={4}>PHÒNG KHÁM 3</MenuItem>
      <MenuItem value={5}>PHÒNG KHÁM 4</MenuItem>
    </Select>
  );
}
