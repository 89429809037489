import { AppThemeOptions } from 'app/theme';

export default {
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          minHeight: '40px',
        },
      },
    },
  },
} as AppThemeOptions;
