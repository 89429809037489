import { AxiosRequestConfig } from 'axios';

export type HttpRequestOptionProps<RPD> = Pick<
  AxiosRequestConfig,
  | 'baseURL'
  | 'url'
  | 'method'
  | 'params'
  | 'data'
  | 'headers'
  | 'timeout'
  | 'responseType'
> & {
  successResponseFormatter?: (response: unknown) => RPD;
};

/**
 * @RQP Request Params Props
 * @RQB Request Body Props
 */
export interface HttpRequestData<RQP, RQB> {
  params?: RQP;
  body?: RQB;
}

/**
 * Default option
 */
export const defaultOption: HttpRequestOptionProps<unknown> = {
  headers: {
    'test-http-request-default-header': '*',
  },
  timeout: 30000,
  responseType: 'json',
};
