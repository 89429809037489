import { forwardRef, useCallback, useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import UserMenu from './UserMenu';
import { AppContext } from 'app/context';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { CoreStore } from 'store';
import { dark, light } from 'styles/themes';

export default forwardRef<HTMLDivElement, AppBarProps>((props, ref) => {
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();

  const currentGrantedPageRouteConfigs = useAppSelector(
    CoreStore.select('currentGrantedPageRouteConfigs'),
  );
  const sidebarOpen = useAppSelector(CoreStore.select('sidebarOpen'));

  const openSidebar = useCallback(() => {
    dispatch(CoreStore.actions.openSidebar());
  }, [dispatch]);

  const closeSidebar = useCallback(() => {
    dispatch(CoreStore.actions.closeSidebar());
  }, [dispatch]);

  return (
    <AppBar ref={ref} position="sticky" {...props}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={sidebarOpen ? closeSidebar : openSidebar}
        >
          {sidebarOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {currentGrantedPageRouteConfigs?.title || ''}
        </Typography>
        <Switch
          color="secondary"
          checked={appContext.selectedTheme === dark}
          onChange={e => {
            if (e.target.checked) {
              appContext.selectTheme(dark);
            } else {
              appContext.selectTheme(light);
            }
          }}
        />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
});
