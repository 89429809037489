import { initialState } from './initial-state';
import { appCreateSlice } from 'app/redux/tools';

export default appCreateSlice({
  name: 'book',
  initialState: initialState,
  reducers: {
    //
  },
});
