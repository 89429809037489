import lazyload from 'app/router/lazyload';

const ValencyManagerPage = lazyload(
  () => import('./pages/valency-manager'),
  module => module.default,
);

const ChemotherapyDiagramPage = lazyload(
  () => import('./pages/chemotherapy-diagram-page'),
  module => module.default,
);

export default {
  route: {
    key: 'chemotherapy',
    path: '/chemotherapy',
    title: 'Quản lý hoá trị',
    permission: {
      permissions: ['MEDICAL_RECORD'],
    },
    children: [
      {
        key: 'valency-manager',
        path: '/valency-manager',
        title: 'Quản lý bệnh nhân hóa trị',
        Component: ValencyManagerPage,
      },
      {
        key: 'diagram',
        path: '/diagram',
        title: 'Quản lý sơ đồ ghế',
        Component: ChemotherapyDiagramPage,
      },
    ],
  },
} as AppModuleProps;
