/* eslint-disable import/order */
/* eslint-disable import/first */
import { ModalController } from './ModalController';
export { default as ModalContainer } from './ModalContainer';
export type { ModalBaseProps, ModalWrapperProps } from './ModalController';
export { ModalController };

// Default modal controller
import Wrapper from './wrapper';
export const modalControl = new ModalController({
  WrapperComponent: Wrapper,
}).mount();
export default modalControl;
