import React, { useState } from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'app/redux/hooks';
import authApi from 'services/auth';
import { CoreStore } from 'store';

export default function UserMenu() {
  const userInfo = useAppSelector(CoreStore.select('userInfo'));

  const [logout] = authApi.useLogoutMutation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({
      options: {
        showLoading: true,
      },
    });
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            px: 2,
            py: 1,
          },
        }}
      >
        <Card
          variant="outlined"
          sx={{
            padding: 1,
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar />
            <Typography fontWeight="bold" px={1}>
              {`${userInfo?.firstName} ${userInfo?.lastName}`.trim()}
            </Typography>
          </Box>
        </Card>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
