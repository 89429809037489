import React, { PropsWithChildren, useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AppContext } from 'app/context';

export default function AppThemeProvider({
  children,
}: PropsWithChildren<unknown>) {
  const appContext = useContext(AppContext);
  return (
    <ThemeProvider theme={appContext.selectedTheme}>{children}</ThemeProvider>
  );
}
