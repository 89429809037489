/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosError, AxiosInstance, Method } from 'axios';
import _ from 'lodash';
import { HttpRequestError } from './error';
import {
  HttpRequestData,
  HttpRequestOptionProps,
  defaultOption,
} from './http-request';
import { ClientErrorProps, HttpResponse } from './http-response';

/**
 * @SE Server Error Response Props
 */
export class HttpRequest<SE> {
  axiosInstance: AxiosInstance;
  #_otp: HttpRequestOptionProps<unknown>;

  /**
   * Create an http request instance with base url
   * @param option
   */
  constructor(option?: HttpRequestOptionProps<unknown>) {
    const otp: HttpRequestOptionProps<unknown> = {
      ...defaultOption,
      ...option,
      headers: {
        ...defaultOption.headers,
        ...option?.headers,
      },
    };
    this.axiosInstance = axios.create(otp);
    this.#_otp = otp;
  }

  /**
   * Calling an http request
   * @RPD Response Data Props
   * @RQP Request Params Props
   * @RQB Request Body Props
   * @param method
   * @param url
   * @param reqData
   * @returns
   */
  async request<RPD, RQP, RQB>(
    options: HttpRequestOptionProps<RPD>,
    url?: string,
    reqData?: HttpRequestData<RQP, RQB>,
  ): Promise<RPD>;
  async request<RPD, RQP, RQB>(
    method: Method,
    url: string,
    reqData?: HttpRequestData<RQP, RQB>,
  ): Promise<RPD>;
  async request<RPD, RQP, RQB>(
    methodOrOptions: unknown,
    url?: string,
    reqData?: HttpRequestData<RQP, RQB>,
  ) {
    const response: HttpResponse<RPD, SE> = {
      status: 'client_error',
    };
    try {
      let config: HttpRequestOptionProps<RPD>;
      let formatter = this.#_otp.successResponseFormatter;
      if (typeof methodOrOptions === 'string') {
        config = {
          url: encodeURI(url!),
          method: methodOrOptions as Method,
          params: reqData?.params,
          data: reqData?.body,
        };
      } else {
        config = methodOrOptions as HttpRequestOptionProps<RPD>;
        formatter = config.successResponseFormatter || formatter;
      }
      const success = await this.axiosInstance(config);
      response.status = 'success';
      response.httpStatusCode = success.status;
      response.httpStatusMessage = success.statusText;
      response.data = success.data as RPD;
      return formatter ? (formatter(response.data) as RPD) : response.data;
    } catch (error) {
      response.status = 'client_error';
      if ((error as AxiosError)?.isAxiosError) {
        const err = error as AxiosError;
        if (err.response?.status) {
          response.status = 'server_error';
          response.httpStatusCode = err.response.status;
          response.httpStatusMessage = err.response.statusText || err.message;
          response.error = err.response.data as SE;
        } else {
          response.error = {
            code: err.code,
            message: err.message,
          } as ClientErrorProps;
        }
      } else {
        response.error = {
          code: _.get(error, 'code'),
          message: _.isString(error)
            ? error
            : _.get(error, 'message', 'request catch error'),
        } as ClientErrorProps;
      }
    }
    throw new HttpRequestError(response);
  }

  setHeaders(headers: Record<string, string>) {
    for (const key of Object.keys(headers)) {
      this.axiosInstance.defaults.headers[key] = headers[key];
    }
  }

  setAuthorizationToken(token: string) {
    this.setHeaders({
      Authorization: token,
    });
  }
}

export { MockResponseConfig } from './mock';
export type { HttpRequestOptionProps, HttpRequestData };
export type { HttpResponse };
export { HttpRequestError };
