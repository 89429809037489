import lazyload from 'app/router/lazyload';

const MedicalRecordManagerPage = lazyload(
  () => import('./pages/medical-record-manager-page'),
  module => module.default,
);

export default {
  route: {
    key: 'medical-record',
    path: '/medical-record',
    title: 'Hồ sơ bệnh án',
    permission: {
      permissions: ['MEDICAL_RECORD'],
    },
    children: [
      {
        key: 'medical-record-manager',
        path: '/medical-record-manager',
        title: 'Quản lý bệnh án',
        Component: MedicalRecordManagerPage,
      },
    ],
  },
} as AppModuleProps;
