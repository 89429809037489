import lazyload from 'app/router/lazyload';

const RadiotherapyPatientsPage = lazyload(
  () => import('./pages/radiotherapy-patient-page'),
  module => module.default,
);

const RadiotherapyDiagramPage = lazyload(
  () => import('./pages/radiotherapy-diagram-page'),
  module => module.default,
);

export default {
  route: {
    key: 'radiotherapy',
    path: '/radiotherapy',
    title: 'Quản lý xạ trị',
    permission: {
      permissions: ['MEDICAL_RECORD'],
    },
    children: [
      {
        key: 'patient',
        path: '/patient',
        title: 'Quản lý bệnh nhân xạ trị',
        Component: RadiotherapyPatientsPage,
      },
      {
        key: 'diagram',
        path: '/diagram',
        title: 'Quản lý sơ đồ máy',
        Component: RadiotherapyDiagramPage,
      },
    ],
  },
} as AppModuleProps;
