type KeyType =
  | '__USER_INFO'
  | '__AUTH_PERMISSIONS'
  | '__AUTH_TOKEN'
  | '__DUMMY_BOOKS';

function _set(key: KeyType, value: string | number) {
  localStorage.setItem(key, value.toString());
}

function _get(key: KeyType) {
  return localStorage.getItem(key) || undefined;
}

export function set<T>(key: KeyType, value: T) {
  _set(key, JSON.stringify(value));
}

export function get<T>(key: KeyType) {
  try {
    const value = _get(key);
    if (!value) return undefined;
    return JSON.parse(value) as T;
  } catch (error) {
    return undefined;
  }
}

export function exist(...keys: KeyType[]) {
  for (const key of keys) {
    if (!_get(key)) return false;
  }
  return true;
}

export function remove(...keys: KeyType[]) {
  for (const key of keys) {
    localStorage.removeItem(key);
  }
}

export function count() {
  return localStorage.length;
}

export function clear(...exceptKeys: KeyType[]) {
  const excepts = exceptKeys
    .map(k => ({ key: k, value: _get(k) }))
    .filter(e => e.value);
  localStorage.clear();
  for (const e of excepts) {
    _set(e.key, e.value as string);
  }
}
