import { AxiosRequestConfig } from 'axios';

/**
 * Config response for axios-mock-adapter
 */
export class MockResponseConfig<S, H, E> {
  requestConfig?: AxiosRequestConfig = undefined;
  statusCode = -1;
  data?: S | E = undefined;
  headers?: H = undefined;

  constructor(requestConfig: AxiosRequestConfig) {
    this.requestConfig = requestConfig;
  }

  reply(statusCode?: number, data?: S, headers?: H) {
    this.statusCode = statusCode || this.statusCode;
    this.data = data || this.data;
    this.headers = headers || this.headers;

    // Logger
    const url = new URL(
      this.requestConfig?.url || '',
      this.requestConfig?.baseURL,
    );
    url.search = new URLSearchParams(this.requestConfig?.params).toString();
    console.groupCollapsed(
      '[MOCK-API]',
      `[${this.requestConfig?.method?.toUpperCase()}]`,
      url.href,
    );
    let reqData = this.requestConfig?.data;
    try {
      reqData = JSON.parse(reqData);
    } catch (e) {
      //
    }
    console.log('[REQUEST]', reqData || {});
    console.log('[RESPONSE]', this.data);
    console.groupEnd();

    return [this.statusCode, this.data, this.headers];
  }

  setSuccess(data?: S, headers?: H) {
    this.statusCode = 200;
    this.data = data;
    this.headers = headers || this.headers;
  }

  replySuccess(data?: S, headers?: H) {
    this.setSuccess(data, headers);
    return this.reply();
  }

  setBadRequest(data: E, headers?: H) {
    this.statusCode = 400;
    this.data = data;
    this.headers = headers || this.headers;
  }

  replyBadRequest(data: E, headers?: H) {
    this.setBadRequest(data, headers);
    return this.reply();
  }

  setNotFound(data: E, headers?: H) {
    this.statusCode = 404;
    this.data = data;
    this.headers = headers || this.headers;
  }

  replyNotFound(data: E, headers?: H) {
    this.setBadRequest(data, headers);
    return this.reply();
  }
}
