import { AppRoutes } from './router';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { CoreStore } from 'store';

export default function App() {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(CoreStore.select('isAuthenticated'));
  const userPermissions = useAppSelector(CoreStore.select('userPermissions'));

  return (
    <AppRoutes
      isAuthenticated={isAuthenticated}
      userPermissions={userPermissions}
      onGranted={grantedPageRouteConfigs => {
        dispatch(
          CoreStore.actions.setGrantedPageRouteConfigs(grantedPageRouteConfigs),
        );
      }}
    />
  );
}
