/* eslint-disable @typescript-eslint/no-explicit-any */
import { DependencyList, useMemo } from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridValidRowModel,
} from '@mui/x-data-grid';

export function createColumnsWithExtraApi<
  A,
  R extends GridValidRowModel = any,
  V = any,
  F = V,
>(factory: (extraApi: A) => GridColDef<R, V, F>[]) {
  return {
    // Hook
    useToColumns: function useToColumns(
      extraApiFactory: () => A,
      extraDeps: DependencyList | undefined = [],
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      return useMemo(() => factory(extraApiFactory()), extraDeps);
    },
  };
}

export function usePaginationModal(page, pageSize) {
  return useMemo(
    () =>
      ({
        page,
        pageSize,
      } as GridPaginationModel),
    [page, pageSize],
  );
}
