import React, { forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export default forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box ref={ref} textAlign="center" {...props}>
      <Divider light />
      <Typography py={2}>Copyright © 2023 TEKMEDI</Typography>
    </Box>
  );
});
