import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { WrapperProps } from '.';
import { ModalWrapperProps } from '@libs/dynamic-modal';

export default function DefaultLayout({
  Component,
  props,
  wrapperProps,
}: ModalWrapperProps<WrapperProps>) {
  return (
    <Box display="flex" flexDirection="column">
      <Box
        bgcolor="primary.main"
        py={1}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="white"
        fontSize="1.2em"
        fontWeight="bold"
      >
        {wrapperProps?.title || <div></div>}
        <IconButton
          size="small"
          sx={{ color: 'white' }}
          onClick={() => props?.modalThis?.close()}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {typeof Component === 'function' && <Component {...props} />}
    </Box>
  );
}
