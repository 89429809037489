import { PropsWithChildren, forwardRef, useMemo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import classNames from 'classnames';

interface PageContentProps extends BoxProps {
  fixed?: boolean;
  fullScreen?: boolean;
}
export default forwardRef<HTMLDivElement, PropsWithChildren<PageContentProps>>(
  ({ children, fixed, fullScreen, ...props }, ref) => {
    const sx: SxProps<Theme> | undefined = useMemo(
      () =>
        fixed || fullScreen
          ? {
              position: fullScreen ? 'fixed' : 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              overflow: 'auto',
              zIndex: fullScreen ? 1200 : 'unset',
              ...props.sx,
            }
          : props.sx,
      [props.sx, fixed, fullScreen],
    );

    return (
      <Box
        ref={ref}
        padding={2}
        {...props}
        sx={sx}
        className={classNames('fade-in', props.className)}
      >
        {children}
      </Box>
    );
  },
);
