import lazyload from 'app/router/lazyload';

const InjectionsManagerPage = lazyload(
  () => import('./pages/injections-manager-page'),
  module => module.default,
);

export default {
  route: {
    key: 'injections-management',
    path: '/injections-management',
    title: 'Quản lý tiêm thuốc',
    permission: {
      //   permissions: ['RADIATION_THERAPY'],
      permissions: ['MEDICAL_RECORD'],
    },
    Component: InjectionsManagerPage,
  },
} as AppModuleProps;
