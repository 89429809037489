import { PropsWithChildren, useEffect } from 'react';

interface HeadProps {
  title: string;
}
export default function Head({
  children,
  title,
}: PropsWithChildren<HeadProps>) {
  useEffect(() => {
    document.title = title;
    return () => {
      if (document.title === title) {
        document.title = '';
      }
    };
  }, [title]);
  return <>{children}</>;
}
