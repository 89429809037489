import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { WrapperProps } from '.';
import { ModalWrapperProps } from '@libs/dynamic-modal';

export interface ComfirmationLayoutProps {
  onConfirm?: () => void;
  confirmButtonLabel?: string;
  disabledCancelButton?: boolean;
}

export default function ConfirmationLayout({
  Component,
  props,
  wrapperProps,
}: ModalWrapperProps<WrapperProps>) {
  const { onConfirm, confirmButtonLabel, disabledCancelButton } =
    (wrapperProps?.layoutProps || {}) as ComfirmationLayoutProps;
  return (
    <Box display="flex" flexDirection="column">
      <Box
        bgcolor="primary.main"
        py={1}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="white"
        fontSize="1.2em"
        fontWeight="bold"
      >
        {wrapperProps?.title || <div></div>}
        <IconButton
          size="small"
          sx={{ color: 'white' }}
          onClick={() => props?.modalThis?.close()}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box p={2} pb={0}>
        {typeof Component === 'function' && <Component {...props} />}
      </Box>

      <Box display="flex" justifyContent="center" gap={2} p={2}>
        {!disabledCancelButton && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => props?.modalThis?.close()}
          >
            Thoát
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            props?.modalThis?.close();
            onConfirm?.();
          }}
        >
          {confirmButtonLabel ? confirmButtonLabel : 'Đồng ý'}
        </Button>
      </Box>
    </Box>
  );
}
